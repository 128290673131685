import './radio-group.css';

class RadioGroupComponent extends HTMLElement {
    constructor() {
        super();
    }

    static get observedAttributes() {
        return ['label', 'list'];
    }

    connectedCallback() {

        const content = document.createElement('div')
        content.innerHTML = `
                <div>
                        <div class="label">${this.label}*</div>
                        <div style="display:inline-flex; flex-wrap: wrap; gap:2px;">
                        ${JSON.parse(this.list).map((opt) => {
            return `
                                    <label class="btn">
                                        <input type="radio" required
                                            value="${opt}"
                                            name="${this.id}">${(opt + '').length === 1 ? `&nbsp;${opt}&nbsp;` : opt}</label>


                                            `
        }).join('')}
                        </div>
                </div>`;
        this.appendChild(content)
        this.checked = null;
        this.querySelectorAll('input')
            .forEach((el) => {
                el.addEventListener('click', ({ target }) => {

                    if (this.checked) this.checked.closest('label').classList.remove('btn-active');
                    this.checked = document.querySelector(`input[name=${this.id}]:checked`);
                    this.checked.closest('label').classList.add('btn-active');
                    this.dispatchEvent(new CustomEvent('CHANGED', { detail: { [this.id]: target.value } }));

                })
            })
    }

    attributeChangedCallback(attr, oldVal, newVal) {
        this[attr] = newVal;
    }
}

customElements.define('ui-radio-group', RadioGroupComponent);
